.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.144);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  background-color: rgb(255, 255, 255);
  width: 78%;
  max-width: 400px;
  padding: 30px;
  border-radius: 10px;

  &_nightMode {
    background-color: #1a1a1a;
  }

  &_nightMode &_header {
    color: white;
  }
  &_nightMode &_form input {
    color: white !important;
    background-color: #262626;
    border: 2px solid #262626;
  }
  &_header {
    margin: 0;
    margin-bottom: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
  }

  &_form {
    width: 100%;
    display: flex;
    flex-direction: column;

    & input {
      box-sizing: border-box;
      width: 100%;
      border: 2px solid #e5e5e5;
      border-radius: 5px;
      padding: 8px 13px;
      font-size: 15px;
      color: #172b4d;

      &:focus {
        outline: none;
        border: 2px solid rgb(106, 141, 177);
      }

      &[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  &_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    flex-direction: column;
    &__submit {
      background-image: url("../../../../assets/images/buttonBg.png");
      background-position: 4% 92%;
      background-size: 250%;
      margin-bottom: 15px;
      color: white;
      border: none;
      padding: 10px 25px;
      cursor: pointer;
      font-size: 17px;
      font-weight: 600;
      border-radius: 120px;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    &__cancel {
      color: #768bb2;
      cursor: pointer;
      text-decoration: underline;
      &:focus {
        outline: none;
      }
    }
  }
}
.popupButton {
  color: white;
  background-image: url("../../../../assets/images/buttonBg.png");
  border: none;
  background-repeat: no-repeat;
  background-position: 0% 90%;
  background-size: cover;
  padding: 15px 25px;
  cursor: pointer;
  font-size: 19px;
  border-radius: 50px;
  font-weight: 600;

  &:focus {
    outline: none;
    background-color: #00a2ec;
  }

  &:hover {
    background-color: #00a2ec;
  }
}

.formSuccess {
  color: green;
  text-align: center;
  font-weight: 600;
}

.inputWrapper{
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  & label {
    margin-bottom: 10px;
    color: #434343;
    font-size: 15px;
  }
}