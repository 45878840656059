.footer_container {
    background-image: url("https://uploads-ssl.webflow.com/5eb05f4f6e90da9be00ef01f/6026f02dc8f6e23c7d39ba27_Footer%20BG%201280.svg");
    background-repeat: no-repeat;
    background-position: left top;
    position: relative;
    bottom: 0;
    background-size: cover;
    width: 100%;
    /*  height:500px; */
    margin-top: 100px;
    padding-top: 100px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
    justify-content: center;
}
.footer_header {
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    margin: 10px 0;
}
.footer_subheader {
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    padding: 0;
    margin: 3px 0 0 0;
}
.footer_column {
    display: flex;
    margin: 0;
    padding: 0 15px 25px 15px;
    max-width: 220px;
    width: auto;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: min-content;
}
.footer_note {
    color: #c3d0ea;
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 10px 0;
}
.footer_menu_item {
    padding: 3px 0;
    font-size: 13px;
    line-height: 23px;
    color: #fff;
    text-decoration: none;
}
.footer_menu_item:hover {
    color: #c3d0ea;
    text-decoration: underline;
}
.footer_logo {
    width: 130px;
    margin-top: -15px;
    margin-left: -25px;
}
.social_link {
    padding: 0 10px 0 0;
    opacity: 0.75;
}
.social_link:hover {
    opacity: 1;
}
.footer_social {
    display: flex;
}
.copywrights {
    grid-column: 1 / span 2;
}
.footer_note_link {
    color: #c3d0ea;
    text-decoration: none;
}
.footer_note_link:hover {
    text-decoration: underline;
}
@media screen and (max-width: 767px) {
    .footer_container {
        min-height: auto;
        background-image: url(https://uploads-ssl.webflow.com/5eb05f4f6e90da9be00ef01f/602890dd9f87566f6b8637cc_Footer%20mobile%20bg.png);
        background-position: 0 0;
        background-repeat: no-repeat;
        grid-template-columns: auto auto;
    }
    .footer_column {
        min-width: 40%;
    }
    .copywrights {
        text-align: center;
    }
    .footer_logo {
        margin-left: -10px;
    }
}

@media screen and (max-width: 479px) {
    .footer_container {
        grid-template-columns: auto;
        justify-items: center;
    }
    .copywrights {
        grid-column: 1 / span 1;
    }
    .footer_header,
    .footer_subheader,
    .footer_note,
    .footer_menu_item {
        text-align: center;
    }
    .footer_social {
        justify-content: center;
    }
    .footer_logo {
        margin: 0;
        padding: 0;
    }
}
