.DateRangePicker {
  position: fixed !important;
  top: 80px;
  left: 80px;
  background-color: white;
  border: 1px solid #d5d3d3;
  font-family: "Roboto", sans-serif;
  border-radius: 6px;
  box-shadow: 0px 3px 10px #2c28281c;
  @media screen and (max-width: 600px) {
    left: 150px;
  }
}
.DateRangePicker__PaginationArrowIcon--next {
  border-left: 8px solid #00aeff !important;
}
.DateRangePicker__PaginationArrowIcon--previous {
  border-right: 8px solid #00aeff !important;
}
.DateRangePicker__CalendarSelection {
  border: 1px solid #00aeff !important;
}
.DateRangePicker__CalendarSelection--is-pending {
  background-color: #00aeff !important;
}
.backDrop {
  position: fixed;
  top: 0;
  left: 52px;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.281);
  @media screen and (max-width: 600px) {
    left: 0px;
    top: 50px;
  }
}

.calenderIcon {
  position: absolute;
  cursor: pointer;
  width: 22px;
  right: 60px;
  top: 15px;
  opacity: 0.9;
  user-select: none;
  transform: translateY(2px);
}

.calenderContainer {
  background-color: white;
  position: absolute;
  width: 240px;
  padding: 10px;
  border-radius: 10px;
  top: 40px;
  z-index: 200;
  right: 10px;
  transform: translate(0, 100%);

  & h4 {
    margin: 0;
    text-align: center;
    margin-bottom: 7px;
  }

  & p {
    font-size: 13px;
    margin: 0;
  }
}

.DateRangePicker__Card {
  position: fixed !important;
  top: 0px;
  left: 0px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #d5d3d3;
  font-family: "Roboto", sans-serif;
  border-radius: 6px;
  box-shadow: 0px 3px 10px #2c28281c;
  @media screen and (max-width: 600px) {
    left: 0px;
  }
}

.dateSelector {
  margin: 0 15px;

  &__normal {
    position: absolute;
    top: 13px;
    right: 35px;
  }

  &__withTogglers {
    position: relative;

    @media screen and (max-width: 800px) {
      position: absolute;
      top: 15px;
      right: 35px;
    }
  }
  &_icon {
    cursor: pointer;
    width: 22.5px;
    user-select: none;
    transform: translateY(2px);
  }
}
