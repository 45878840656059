.sideBar {
  flex: 0 0 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  order: 0;
  padding: 0 !important;
  @media screen and (max-width: 700px) {
    justify-content: center;
  }
  &_container {
    background-image: url("../../../assets/images/sidebar/sidebarBg.svg");
    background-repeat: no-repeat;
    background-position: auto;
    width: 52px;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 15px 0;
    box-sizing: border-box;

    &__top {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 700px) {
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        order: 2;
      }
    }

    @media screen and (max-width: 700px) {
      position: relative;
      display: flex;
      flex-direction: row;
      align-content: center;
      width: 100%;
      padding: 5px 0;
    }
  }
  @media screen and (max-width: 700px) {
    padding: 0 10px;
  }
  &_logo {
    height: 45px;
    user-select: none;
    @media screen and (max-width: 700px) {
      order: 0;
    }
  }

  &_icon {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    user-select: none;
    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.329);
    }
    & img {
      width: 20px;
    }

    &__home {
      margin-bottom: 20px !important;
      @media screen and (max-width: 700px) {
        margin-bottom: 0 !important;
      }
    }
    &__dollor {
      & img {
        width: 16px;
      }
    }
  }
}

// Export Dialog
.exportDialog {
  position: fixed !important;
  top: 120px;
  left: 80px;
  background-color: white;
  border: 1px solid #d5d3d3;
  font-family: "Roboto", sans-serif;
  min-height: 100px;
  min-width: 150px;
  padding: 20px 50px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #2c28281c;
  text-align: center;
  @media screen and (max-width: 700px) {
    right: 10px;
    top: 80px;
  }

  &_nightMode {
    color: white !important;
    background-color: #262626 !important;
    border: 1px solid #262626;
  }

  &_nightMode &_iconContainer__item {
    background-color: #1a1a1a !important;
  }

  &_iconContainer {
    display: inline-block;
    cursor: pointer;

    &__item {
      margin: 5px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f8f8f8;
      border-radius: 100%;
      width: 130px;
      height: 130px;
      shape-outside: c;
      @media screen and (max-width: 700px) {
        width: 115px;
        height: 115px;
      }
      & img {
        width: 65px;
        @media screen and (max-width: 700px) {
          width: 50px;
        }
      }

      &:hover {
        box-shadow: 0 0 0 5px #3685b7;
      }
    }
    div {
      margin-top: 3px;
    }
  }

  h3 {
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}

.backDrop {
  position: fixed;
  top: 0;
  left: 52px;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.281);
  @media screen and (max-width: 700px) {
    left: 0px;
    top: 55px;
  }
}