.setting {
  display: flex;
  width: 100%;
  max-width: 1200px;
  min-height: 500px;

  &_left {
    background-color: transparent;
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  &_image {
    width: 70%;
    @media screen and (max-width: 900px) {
      width: 90%;
    }
  }

  &_right {
    position: relative;
    box-sizing: border-box;
    background-color: white;
    flex: 0.5;
    box-shadow: 0px 2px 4px #00000015;
    border-radius: 20px;
    padding: 15px 30px;
    padding-top: 0;
    margin-top: 70px;
    @media screen and (max-width: 900px) {
      padding: 50px 20px;
      padding-top: 0;
    }

    @media screen and (max-width: 850px) {
      flex: 1;
    }
  }

  &_nightMode &_right {
    background-color: #1a1a1a !important;
  }

  &_nightMode .listWrap_item {
    color: #bebebe !important;
    background-color: #262626 !important;
    border: 1px solid #1a1a1a !important;
    border-bottom: 0 !important;
  }

  &_nightMode &_connectForm__header,
  &_nightMode .listWrap_item__active,
  &_nightMode .inputContainer_label {
    color: white !important;
  }

  &_nightMode .inputContainer_input {
    background-color: #262626 !important;
    border: 1px solid #818181 !important;
    color: white !important;
  }

  &_connectForm {
    &__header {
      color: #172b4d;
      font-weight: 400;
      border-bottom: 1px solid #bcbcbc;
      padding-bottom: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &__message {
      color: #f76277;
      font-weight: 400;
    }
  }
}

.buttonDash {
  background-image: url("../../assets/images/buttonBg.png");
  font-weight: 600;
  color: white;
  background-repeat: no-repeat;
  background-position: 0% 90%;
  background-size: cover;
  border: none;
  width: 150px;
  padding: 10px 0;
  cursor: pointer;
  font-size: 16px;
  border-radius: 20px;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.footerSettings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.leftAligner {
  @media screen and (max-width: 500px) {
    order: 2;
  }
}

.rightAligner {
  @media screen and (max-width: 500px) {
    margin-bottom: 20px;
    order: 1;
  }
}

.listWrap {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding: 0 23px;
  box-sizing: border-box;
  transform: translateY(-100%);

  &_item {
    padding: 10px 0;
    flex: 0.5;
    margin-right: 1px;
    color: #504f4f;
    text-align: center;
    cursor: pointer;
    background-color: white;
    transition: 0.0001s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #d0d0d0;
    border-bottom: 0;
    &__active {
      background-image: url("../../assets/images/buttonBg.png");
      font-weight: 600;
      color: white;
      background-repeat: no-repeat;
      background-position: 0% 90%;
      background-size: cover;
    }

    &:active,
    &:focus,
    &:hover {
      color: white;
      font-weight: 600;
      background-repeat: no-repeat;
      background-position: 0% 90%;
      background-size: cover;
      background-image: url("../../assets/images/buttonBg.png");
    }
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;

  &_label {
    margin-bottom: 10px;
    color: #434343;
    font-size: 15px;
  }
  &_errorMessage {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 600;
    color: #f12d68;
  }

  &_successMessage {
    margin-top: 4px;
    font-size: 15px;
    font-weight: 600;
    color: #08a54f;
  }

  &_input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #bebebe;
    border-radius: 3px;
    padding: 8px 10px;
    font-size: 18px;
    color: #434343;
    outline: none;
  }

  &_submitBtn {
    background-image: url("../../assets/images/buttonBg.png");
    font-weight: 600;
    color: white;
    background-repeat: no-repeat;
    background-position: 0% 90%;
    background-size: cover;
    border-radius: 20px;
    border: none;
    min-width: 150px;
    padding: 10px 10px;
    cursor: pointer;
    font-size: 16px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }

    &:focus {
      outline: none;
      background-color: #00a2ec;
    }
    &:hover {
      background-color: #078ecc;
    }
    &:disabled {
      background-color: #61c0ec;
      cursor: not-allowed;
    }
  }
}

.dataSourceConnectMessage {
  color: #fa0b53;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
}

.userListTable {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-collapse: collapse;

  &_wrapper {
    max-height: 340px;
    overflow: scroll;
  }

  &_header {
    position: sticky;
    top: 0;
    left: 0;
  }

  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 14px;

    &:not(first-child) {
      text-align: center;
    }
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0561b1;
    color: white;
  }

  tr {
    &:nth-child(even){
      background-color: #E6F6FE;
    }

    &:nth-of-type(odd) {
      background-color: white;
    }
  }

  &_iconBlock {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    &_icon {
      width: 23px;
      cursor: pointer;
    }
  }

  &_hideMobile {
    @media screen and (max-width: 850px) {
      display: none;
    }
  }
  
}


.inputWrapper{
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  & label {
    margin-bottom: 10px;
    color: #434343;
    font-size: 15px;
  }
}