.box {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  padding-top: 0;
  border-bottom: 1px solid #bcbcbc;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
  &_loading {
    color: #7e98b2;
    margin: 0;
  }

  &_nightMode .titleWrapper_title {
    color: white !important;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  &_img {
    width: 30px;
    margin-right: 15px;
  }

  &_title {
    font-weight: 500;
    color: #172b4d;
    font-size: 17px;
    margin: 0;
  }
}

.connectButton {
  background-image: url("../../assets/images/buttonBg.png");
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 220%;
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  font-family: inherit;
  cursor: pointer;
  border: 0;
  font-size: 16px;
  border-radius: 20px;

  &:active,
  &:focus {
    outline: none;
  }
}

.connetionSetting {
  display: flex;
  align-items: center;

  &_gear {
    & img {
      width: 30px;
    }
  }
}

.connected {
  display: flex;
  align-items: center;
  color: green;
  margin: 0 10px;
  font-weight: 500;
}

.accountNotSelected {
  display: flex;
  align-items: center;
  color: rgb(224, 114, 24);
  margin: 0 10px;
  font-weight: 500;

  &_icon {
    margin-right: 0px;
  }
}

.connectIcon {
  margin-right: 6px;
}


.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 140px;
  background-color: rgb(36, 36, 36);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
